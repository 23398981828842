.privacyMain {
  min-height: 100vh;
  /* background-image: linear-gradient(to right, #434343 0%, black 100%); */
  background-image: linear-gradient(315deg, #ff4e00 0%, #ec9f05 74%);
  text-align: center;
  margin-top:5px;
  color: white;
}

.privacyLogoReference{
  width: 150px;
  /* box-shadow: 0px 5px rgb(71, 71, 71); */
  margin-bottom: 5px;
  margin-top: 14px;
  height: 150px;
}
